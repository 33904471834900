(function ($) {
  'use strict';

  var $globalHeader = $('.js-site-header');
  var $globalOverlay = $('.js-content-dim');
  var $offer;

  Drupal.behaviors.sitewideBannerV1 = {
    animationTime: 300,
    privateBrowsing: function () {
      try {
        sessionStorage.setItem('sessionTestVar', 1);
        sessionStorage.removeItem('sessionTestVar');
      } catch (err) {
        return true;
      }
    },
    setupOffer: function ($siteBanner) {
      var self = this;

      $offer = $('.js-site-banner-offer', $siteBanner);
      // Any link inside triggers slidedown content
      $('.site-banner-rotator-items a, .js-close-offers', $siteBanner).on('click', function (e) {
        e.preventDefault();
        $offer.toggleClass('active');
        $globalHeader.trigger('gnav.close');

        if ($offer.hasClass('active')) {
          $offer.stop(true, true).slideDown(self.animationTime);
          // When the overlay.closed event is triggered from site header, then close
          // We do this instead of handling the click directly because site header has other interactions to handle first
          $globalOverlay.addClass('active');
          $globalOverlay.one('overlay.closed', self.closeOffer);
        } else {
          self.closeOffer();
        }
      });
    },
    closeOffer: function () {
      var self = this;

      $offer.stop(true, true).slideUp(self.animationTime);
      // Do this in a timeout to preserve alignment
      setTimeout(function () {
        $globalOverlay.removeClass('active');
      }, self.animationTime);
    },
    setupRotator: function ($rotatorItems) {
      setInterval(function () {
        // select the first child each time since the order changes.
        $rotatorItems
          .children()
          .first()
          .slideUp(function () {
            $(this).appendTo($(this).parent()).css({
              overflow: '',
              display: ''
            });
          });
      }, 3000);
    },
    attach: function (context) {
      var self = this;
      var $siteBanner = $('.js-site-banner--v1', context);

      if (!$siteBanner.length || self.privateBrowsing()) {
        return;
      }
      var $rotatorItems = $('.site-banner-rotator-items', $siteBanner);

      if ($rotatorItems.children().length >= 2) {
        self.setupRotator($rotatorItems);
      }

      if ($siteBanner.hasClass('site-banner__slidedown')) {
        self.setupOffer($siteBanner);
      }
    }
  };
})(jQuery);
